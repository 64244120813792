var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-array", staticStyle: { width: "100%" } },
    [
      _vm.carouselConfig.images.length
        ? _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-carousel",
                {
                  staticStyle: { "border-radius": "18px" },
                  attrs: {
                    "hide-delimiters": "",
                    height: _vm.carouselConfig.height,
                    interval: "5000"
                  }
                },
                _vm._l(_vm.carouselConfig.images, function(sr) {
                  return _c(
                    "v-carousel-item",
                    { key: sr },
                    [
                      _c("v-img", {
                        attrs: { height: _vm.carouselConfig.height, src: sr }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showCast,
                expression: "showCast"
              }
            ],
            staticStyle: { "background-color": "#d7ddff" }
          },
          [
            _c("v-container", { staticClass: "px-4 py-0" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "flex-wrap": "nowrap"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "50px",
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center"
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { size: "20", color: "primary" } },
                        [_vm._v("volume_up")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "flex-grow": "1" } },
                    [
                      _c(
                        "v-carousel",
                        {
                          staticClass: "elevation-0 cast",
                          attrs: {
                            "hide-delimiters": "",
                            "hide-controls": "",
                            vertical: "",
                            height: "40",
                            interval: "3000"
                          }
                        },
                        _vm._l(_vm.orders_info, function(v) {
                          return _c("v-carousel-item", { key: v }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "line-height": "40px",
                                  color: "#3F51B5"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(v.email) +
                                    "\n                                        "
                                ),
                                v.country
                                  ? [
                                      _vm._v(
                                        "\n                                           from "
                                      ),
                                      _c("b", [_vm._v(_vm._s(v.country))])
                                    ]
                                  : _vm._e(),
                                _vm._v(
                                  "\n                                        ordered a(n) "
                                ),
                                _c("b", [_vm._v(_vm._s(v.product))]),
                                _vm._v(
                                  " " +
                                    _vm._s(v.readable_delta_time) +
                                    "\n                                    "
                                )
                              ],
                              2
                            )
                          ])
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "50px",
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center",
                        cursor: "pointer"
                      },
                      on: { click: _vm.closeCast }
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { size: "20", color: "primary" } },
                        [_vm._v("close")]
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          ],
          1
        )
      ]),
      _c(
        "v-container",
        { attrs: { "grid-list-xl": "" } },
        [
          _c(
            "v-layout",
            { staticStyle: { width: "100%" }, attrs: { column: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "py-0" },
                [
                  _c("h2", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("All Products")
                  ]),
                  _c(
                    "v-subheader",
                    { staticStyle: { "justify-content": "center" } },
                    [
                      _c("span", [
                        _vm._v(
                          "Select your desired product, choose a plan that fits your needs, then you can start enjoying our service on Kubernetes Cloud."
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._l(_vm.prod, function(v, k) {
                return _c(
                  "v-flex",
                  { key: k },
                  [
                    _c(
                      "v-card",
                      {
                        staticStyle: { "background-color": "inherit" },
                        attrs: { flat: "", tile: "" }
                      },
                      [
                        _c("v-card-title", { staticClass: "pt-0" }, [
                          _c("h3", { staticClass: "fs-1" }, [
                            _vm._v(
                              _vm._s(
                                k !== "Runtimes"
                                  ? k
                                  : "Customizable Runtime Environment"
                              )
                            )
                          ])
                        ]),
                        _c(
                          "v-card-text",
                          { staticClass: "py-0" },
                          [
                            _c(
                              "v-layout",
                              {
                                staticClass: "product-wrap",
                                attrs: { row: "", "align-start": "" }
                              },
                              _vm._l(v, function(o, i) {
                                return _c(
                                  "v-flex",
                                  {
                                    key: i,
                                    attrs: {
                                      xs6: "",
                                      sm4: "",
                                      md3: "",
                                      lg2: ""
                                    }
                                  },
                                  [_c("Product", { attrs: { product: o } })],
                                  1
                                )
                              }),
                              1
                            ),
                            k === "Runtimes"
                              ? _c("p", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    "\n                            Need a different customizable runtime environment? Please "
                                  ),
                                  _c("a", { on: { click: _vm.openFeedback } }, [
                                    _vm._v(
                                      "click\n                            here"
                                    )
                                  ]),
                                  _vm._v(
                                    " to submit your feedback. We will provide more runtime environments soon.\n                        "
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }