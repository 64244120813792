<template>
    <div style="width: 100%;" class="product-array">
            <v-container fluid  v-if="carouselConfig.images.length">
                <v-carousel hide-delimiters :height="carouselConfig.height" style="border-radius: 18px" interval="5000">
                    <v-carousel-item v-for=" sr in carouselConfig.images" :key="sr">
                        <v-img   :height="carouselConfig.height"  :src="sr"></v-img>
                    </v-carousel-item>
                </v-carousel>
            </v-container>
        <transition>
                <div style="background-color: #d7ddff;" v-show="showCast">
                    <v-container   class="px-4 py-0">
                        <div style="display: flex;justify-content: center;flex-wrap: nowrap">
                            <div style="width: 50px; display: flex;justify-content: center;align-items: center">
                                <v-icon size="20" color="primary">volume_up</v-icon>
                            </div>
                            <div style="flex-grow: 1">
                                <v-carousel  hide-delimiters hide-controls vertical  height="40" interval="3000" class="elevation-0 cast">
                                    <v-carousel-item v-for="v in orders_info" :key="v" >
                                        <span  style="line-height: 40px;color:#3F51B5;">
                                            {{v.email}}
                                            <template  v-if="v.country">
                                               from <b>{{v.country}}</b>
                                            </template>
                                            ordered a(n) <b>{{v.product}}</b> {{v.readable_delta_time}}
                                        </span>
                                    </v-carousel-item>
                                </v-carousel>
                            </div>
                            <div  style="width: 50px; display: flex;justify-content: center;align-items: center;cursor: pointer" @click="closeCast">
                                <v-icon size="20" color="primary">close</v-icon>
                            </div>
                        </div>
                    </v-container>
                </div>
        </transition>

        <v-container grid-list-xl>
            <v-layout column style=" width: 100%">
                <v-flex class="py-0">
                    <h2 style="text-align: center;">All Products</h2>
                    <v-subheader style="justify-content: center">
                        <span>Select your desired product, choose a plan that fits your needs, then you can start enjoying our service on Kubernetes Cloud.</span>
                    </v-subheader>
                </v-flex>
                <v-flex v-for="(v, k) in prod" :key="k">
                    <v-card flat tile style="background-color: inherit;">
                        <v-card-title class="pt-0">
                            <h3 class="fs-1">{{ k!=='Runtimes'?k:'Customizable Runtime Environment' }}</h3>
                        </v-card-title>
                        <v-card-text class="py-0">
                            <v-layout row class="product-wrap" align-start >
                                <v-flex xs6 sm4 md3 lg2 v-for="(o,i) in v" :key="i">
                                    <Product :product="o"></Product>
                                </v-flex>
                            </v-layout>
                            <p class="mb-0" v-if="k==='Runtimes'">
                                Need a different customizable runtime environment? Please <a @click="openFeedback">click
                                here</a> to submit your feedback. We will provide more runtime environments soon.
                            </p>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import {products} from '@/module/constant';
    import Product from '@/components/Product'
    import $ from 'jquery'

    export default {
        name: 'index.vue',
        components: {Product},
        data() {
            return {
                showCast : false,
                orders_info : [],
                prod: {
                    Database: [],
                    Runtimes: [],
                    Ecom: [],
                    "Message Queue": [],
                    CMS: [],
                    CRM:[],
                    Education:[],
                    Operations:[]
                }
            };
        },
        computed: {
            carouselConfig(){
                if(this.$vuetify.breakpoint.width >= 1260){
                    return {
                        images: ['https://images.cloudclusters.io/ee93e3ee1c584701bf9853d41c780a64/banner1_1800.jpg','https://images.cloudclusters.io/f94c033f552d43eab550ce9ed99c4967/banner2_1800.jpg'],
                        height: 360
                    }
                }else if (this.$vuetify.breakpoint.width <= 850) {
                    return {
                        images : [],
                        height: 252
                    }
                }else{
                    return {
                        images: ['https://images.cloudclusters.io/dbb3271a2527456c8084629ac73ac834/banner1_1260.jpg','https://images.cloudclusters.io/e6bacd0a121d42e7a009b0a18b577114/banner2_1260.jpg'],
                        height: 252
                    }
                }
            }
        },
        methods: {
            openFeedback() {
                $('#feedbackBtn').trigger('click')
            },
            closeCast(){
                this.showCast = false
            },
            initCastData(){
                this.$http.get(`/orders/info/`).then(({orders_info}) => {
                    if(orders_info.length){
                        this.orders_info = orders_info
                        this.$nextTick(() => {
                            this.showCast = true
                        })
                    }
                })
            },
        },


        created() {
            const keys = Object.keys(products);
            keys.forEach(item => {
                if(item !== 'mongodb'){
                    this.prod[products[item].category].push(products[item]);
                }
            });
            this.initCastData()
            this.$SDK.track({
                pageName : 'Ordering'
            })
        }
    };
</script>

<style lang="scss">
    .product-array {
        .product-wrap {
            flex-wrap: wrap;
        }
        .cast .v-window-item .v-responsive__content b{
            color: #ff6b23;
        };
    }
</style>
